import shippysTreeService from "../../assets/shippys_tree_service_transparent.png";
import {Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Typography, useMediaQuery, useTheme} from "@mui/material";
import React from "react";
import {ExternalCompanyDto} from "../../shared/dtos/company.dto";
import {TreeWithOperation} from "./EstimateItemizedPage";
import {StoredImageDto} from "../../shared/dtos/stored-image.dto";
import {formatTreeOperationOneLine} from "../../shared/services/tree-helpers.service";
import {ItemizedEstimateDto} from "../../shared/dtos/itemized-estimate.dto";
import {preventCacheImageSrc} from "../../shared/misc/image-helpers";

export type EstimateItemizedDisplayType = 'estimate' | 'invoice' | 'receipt';
export type EstimateItemizedDisplayMode = 'admin' | 'customer' | 'contractor';

export interface EstimateItemizedStatelessProps {
  company: ExternalCompanyDto;
  client: {name: string, address: string, email?: string, phoneNumber?: string},
  estimate: ItemizedEstimateDto;
  treesWithOperations: TreeWithOperation[];
  displayType: EstimateItemizedDisplayType;
  displayMode: EstimateItemizedDisplayMode;
  id?: string;
  onImageClick?: (image: StoredImageDto) => void;
  style?: React.CSSProperties;
}

export const EstimateItemizedStateless = React.forwardRef<HTMLDivElement, EstimateItemizedStatelessProps>((props: EstimateItemizedStatelessProps, ref) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const getItemizedType = () => {
    if (props.displayMode === 'contractor') {
      return 'Work Order';
    }
    switch (props.displayType) {
      case 'estimate': return 'Estimate';
      case 'invoice': return 'Invoice';
      case 'receipt': return 'Receipt';
    }
  }

  const calculateTotalCost = (trees: TreeWithOperation[]) =>
    trees.flatMap(t => t.operations).reduce((acc, operation) => acc + (operation.estimatedCost ?? 0), 0);

  const getAllImages = (tree: TreeWithOperation) =>
    [...tree.images, ...tree.operations.flatMap(op => op.images)];

  const getTreeGates = (tree: TreeWithOperation) => (props.estimate?.gates ?? []).filter(gate =>
    gate.associatedTrees.some(t => t.id === tree.id)
  );

  const onImageClick = (image: StoredImageDto) => {
    if (props.onImageClick) {
      props.onImageClick(image);
    }
  }

  return (
    <div ref={ref}>
      <Paper sx={{
        padding: 2,
        margin: 'auto',
        maxWidth: '800px',
        position: 'relative',
        overflow: 'hidden',
        // Use the ::before pseudo-element to apply background with opacity
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.1)', // Change this to your desired color
          zIndex: 0,
        },
        // Ensure that the Paper content is positioned above the ::before background
        '& > *': {
          position: 'relative',
          zIndex: 1,
        },
      }} style={{...props.style}} id={props.id}>
        <div style={{
          pointerEvents: 'none',
          position: 'absolute',
          width: '100%',
          height: '100%',
          left: 0,
          top: 0,
          backgroundImage: `url(${shippysTreeService})`,
          backgroundSize: '800px auto',
          backgroundRepeat: 'repeat-y',
          opacity: 0.1}}>
        </div>
        <div style={{opacity: 1}}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              {isSmallScreen && <img src={shippysTreeService} alt="Company Logo" style={{width: 150}}/>}
              <Typography variant="h3" gutterBottom>
                {props.company.name} {getItemizedType()}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {getItemizedType()} #{props.estimate?.id} - Date: {new Date().toLocaleDateString()}
              </Typography>
            </Box>
            {!isSmallScreen && <img src={shippysTreeService} alt="Company Logo" style={{width: 150}}/>}
          </Box>
          <Typography variant="h6" gutterBottom>Client</Typography>
          <Typography variant="body1" gutterBottom>{props.client.name}</Typography>
          <Typography variant="body1" gutterBottom>{props.client.address}</Typography>
          <Typography variant="body1" gutterBottom>{props.client.email}</Typography>
          <Typography variant="body1" gutterBottom>{props.client.phoneNumber}</Typography>
          <TableContainer>
            <Table aria-label="invoice table" >
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontSize: '1.4rem' }}>Service</TableCell>
                  <TableCell align="right" style={{ fontSize: '1.4rem' }}>{props.displayMode != 'contractor' ? 'Cost' : ''}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.treesWithOperations.map((tree, index) => (
                  <React.Fragment key={index}>
                    <TableRow>
                      <TableCell component="th" scope="row" style={{ fontSize: '1.2rem' }}>
                        {tree.species}
                        <Typography variant="body2" component="div">
                          (Height: {tree.height}, Width: {tree.width}, Trunk Diameter: {tree.trunkDiameter})
                        </Typography>
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '1.2rem' }}>
                        {props.displayMode != 'contractor' ? `$${calculateTotalCost([tree])}` : ''}
                      </TableCell>
                    </TableRow>
                    {tree.operations.map((operation, opIndex) => (
                      <TableRow key={opIndex}>
                        <TableCell style={{ paddingLeft: 40 }}>{formatTreeOperationOneLine(operation)}</TableCell>
                        <TableCell align="right">{props.displayMode != 'contractor' ? `$${operation.estimatedCost ?? -1}` : ''}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell component="th" scope="row" style={{ fontSize: '1.2rem', border: 'none', paddingBottom: '0' }} colSpan={2}>
                        Pictures:
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ paddingLeft: 40, paddingTop: 10, borderBottom: 'none' }} colSpan={2}>
                        {getAllImages(tree).map(image =>
                          <img key={image.id} onClick={() => {onImageClick(image)}} src={preventCacheImageSrc(image.imageUrl)} style={{ width: '100%', maxWidth: 200, marginRight: '10px', cursor: 'pointer'}} crossOrigin={"anonymous"}/>
                        )}
                      </TableCell>
                    </TableRow>
                    {props.displayMode != 'customer' && getTreeGates(tree).length > 0 && <>
                      <TableRow>
                        <TableCell component="th" scope="row" style={{ fontSize: '1.2rem', border: 'none', paddingBottom: '0' }} colSpan={2}>
                          Gates:
                        </TableCell>
                      </TableRow>
                      {getTreeGates(tree).map((gate, index) => (
                        <TableRow key={gate.id}>
                          <TableCell style={{ paddingLeft: 40, borderBottom: 'none' }} colSpan={2}>
                            <Typography variant={'h6'}>
                              {`Gate #${index + 1} (${gate.width ?? 'Unknown'}")`}
                            </Typography>
                            {gate.images.map(image =>
                              <img key={image.id} onClick={() => {onImageClick(image)}} src={preventCacheImageSrc(image.imageUrl)} style={{ width: '100%', maxWidth: 200, marginRight: '10px', cursor: 'pointer'}} crossOrigin={"anonymous"}/>
                            )}
                          </TableCell>
                        </TableRow>)
                      )}
                    </>}
                    {!!tree.notes &&
                    <>
                      <TableRow>
                        <TableCell style={{ paddingTop: 20, borderBottom: 'none' }} colSpan={2}>
                          <Typography variant="h6">
                              Notes:
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ paddingLeft: 40, paddingTop: '0', borderBottom: 'none' }} colSpan={2}>
                          <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                            {tree.notes}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </>}
                    {!!tree.crewNotes && props.displayMode != 'customer' &&
                    <>
                      <TableRow>
                        <TableCell style={{ paddingTop: 20, borderBottom: 'none' }} colSpan={2}>
                          <Typography variant="h6">
                              Crew Notes:
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ paddingLeft: 40, paddingTop: '0', borderBottom: 'none' }} colSpan={2}>
                          <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                            {tree.crewNotes}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </>}
                    <TableRow >
                      <TableCell style={{ padding: 0, borderBottom: 'none' }} colSpan={2}>
                        <div style={{ height: '10px', backgroundColor: '#818181', borderRadius: '3px'}}/>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
                {props.displayMode != 'contractor' && <TableRow>
                    <TableCell component="th" scope="row" style={{ fontWeight: 'bold', fontSize: '1.4rem' }}>Total</TableCell>
                    <TableCell align="right" style={{ fontWeight: 'bold', fontSize: '1.4rem' }}>${calculateTotalCost(props.treesWithOperations)}</TableCell>
                </TableRow>}
              </TableBody>
            </Table>
          </TableContainer>
          {props.displayMode != 'contractor' &&
          <div>
            <Typography variant="body2" sx={{ marginTop: 2 }}>
              Thank you for choosing our services. Please contact us for any further inquiries.
            </Typography>
            {props.displayType === 'estimate' &&
            <Typography variant="body2" sx={{ marginTop: 2 }}>
                This estimate is valid for 30 days.
            </Typography>}
            {props.displayType === 'invoice' &&
            <>
              <Typography variant="body2" sx={{ marginTop: 2 }}>
                  Make payable to: {props.company.name}
              </Typography>
              <Typography variant="body2" sx={{ marginTop: 2 }}>
                  Address: {props.company.billingStreetAddress1} {props.company.billingStreetAddress2 ?? ''} {props.company.billingCity}, {props.company.billingState}, {props.company.billingPostalCode}
              </Typography>
            </>}
            {props.displayType === 'receipt' &&
            <Typography variant="body2" sx={{ marginTop: 2 }}>
                Paid
            </Typography>}
          </div>}
        </div>
      </Paper>
    </div>
  );
})